@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.bubbleUI {
    position: relative;
    background-color: rgba(0, 0, 0, 0) !important;
    width: 100%;
    max-width: 1000px;
    height: 500px;
    border-radius: 50px;
    background-color: white !important;
    overflow: hidden !important;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
  
    margin-bottom: 10px;
  }

  .companyBubble {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: opacity 0.1s ease;
  }
  
  .companyBubble:hover {
    opacity: 0.5;
  }