@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  body {
    background-color: #14161b;
  }
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-dots li.slick-active button:before {
  color:orange !important;
}
